
import { computed, defineComponent, nextTick, onMounted, provide } from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import "@vuepic/vue-datepicker/dist/main.css";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "app",
  setup() {
    ApiService.setHeader();
    const store = useStore();
    const router = useRouter();

    const themeMode = computed(() => store.getters.getThemeMode);

    const can = computed(() => store.getters.hasPermission);

    provide("can", can.value);

    if (themeMode.value === "dark") {
      require("@sweetalert2/theme-dark/dark.scss");
    }

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      // we need to remove this line when we need to apply dark mode
      store.dispatch(Actions.SET_THEME_MODE_ACTION, "light");

      store
        .dispatch(Actions.VERIFY_AUTH, {
          api_token: JwtService.getToken(),
        })
        .then(() => {
          if (!store.getters.isUserAuthenticated) {
            router.push({ name: "sign-in" });
          }
        });

      store.dispatch(Actions.ADD_BODY_CLASSNAME, "x-scroll-hidden");

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
