import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
// import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import Permissions from "@/store/enums/Permissions";
import { computed } from "vue";
import ApiService from "@/core/services/ApiService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/ComponentsList.vue"),
        meta: {
          permission: Permissions.DASHBOARD,
        },
      },
    ],
  },

  {
    path: "/",
    redirect: "charts",
    component: () => import("@/layout/Layout.vue"),
    children: [
      // charts

      {
        path: "/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
      },
    ],
  },

  {
    path: "/",
    redirect: "/password-reset",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/media-library",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/media-library",
        name: "media-library",
        component: () => import("@/views/Media.vue"),
        meta: {
          permission: Permissions.INDEX_FILES_MANAGER,
        },
      },
    ],
  },

  {
    path: "/",
    redirect: "/news",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/news",
        name: "view-news",
        component: () => import("@/views/pages/news/Index.vue"),
        meta: {
          permission: Permissions.INDEX_NEWS,
        },
      },
      {
        path: "/news/create",
        name: "create-news",
        component: () => import("@/views/pages/news/Create.vue"),
        meta: {
          permission: Permissions.STORE_NEWS,
        },
      },
      {
        path: "/news/received/:id",
        name: "edit-news-received",
        component: () => import("@/views/pages/news/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_NEWS,
        },
      },
      {
        path: "/news/edit/:id",
        name: "edit-news",
        component: () => import("@/views/pages/news/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_NEWS,
        },
      },
      {
        path: "/news/trash",
        name: "trashed-news",
        component: () => import("@/views/pages/news/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_NEWS,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/notifications-types",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/notifications-types",
        name: "view-notifications-types",
        component: () => import("@/views/pages/notification-types/Index.vue"),
        meta: {
          permission: Permissions.INDEX_NOTIFICATIONS_TYPE,
        },
      },
      {
        path: "/notifications-types/create",
        name: "create-notifications-types",
        component: () => import("@/views/pages/notification-types/Create.vue"),
        meta: {
          permission: Permissions.STORE_NOTIFICATION_TYPE,
        },
      },
      {
        path: "/notifications-types/edit/:id",
        name: "edit-notifications-types",
        component: () => import("@/views/pages/notification-types/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_NOTIFICATION_TYPE,
        },
      },
      {
        path: "/notifications-types/trash",
        name: "trashed-notifications-types",
        component: () => import("@/views/pages/notification-types/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_NOTIFICATION_TYPE,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/notifications",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/notifications",
        name: "view-notifications",
        component: () => import("@/views/pages/notifications/Index.vue"),
        meta: {
          permission: Permissions.INDEX_NOTIFICATIONS,
        },
      },
      {
        path: "/notifications/create",
        name: "create-notifications",
        component: () => import("@/views/pages/notifications/Create.vue"),
        meta: {
          permission: Permissions.STORE_NOTIFICATIONS,
        },
      },
      {
        path: "/notifications/edit/:id",
        name: "edit-notifications",
        component: () => import("@/views/pages/notifications/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_NOTIFICATIONS,
        },
      },
      {
        path: "/notifications/trash",
        name: "trashed-notifications",
        component: () => import("@/views/pages/notifications/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_NOTIFICATIONS,
        },
      },
    ],
  },

  {
    path: "/",
    redirect: "/sent-news",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/sent-news",
        name: "sent-news",
        component: () => import("@/views/pages/SentNews.vue"),
        meta: {
          permission: Permissions.INDEX_RECEIVED_NEWS,
        },
      },
    ],
  },

  {
    path: "/",
    redirect: "/frequencies",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/frequencies",
        name: "create-frequencies",
        component: () => import("@/views/pages/Frequencies.vue"),
        meta: {
          permission: Permissions.SHOW_ALL_FREQUENCIES,
        },
      },
      {
        path: "/frequencies/:id",
        name: "update-frequencies",
        component: () => import("@/views/pages/Frequencies.vue"),
        meta: {
          permission: Permissions.SHOW_ALL_FREQUENCIES,
        },
      },
    ],
  },

  {
    path: "/",
    redirect: "/special-files",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/special-files",
        name: "view-special-files",
        component: () => import("@/views/pages/special-files/Index.vue"),
        meta: {
          permission: Permissions.INDEX_SPECIAL_FILE,
        },
      },
      {
        path: "/special-files/create",
        name: "create-special-files",
        component: () => import("@/views/pages/special-files/Create.vue"),
        meta: {
          permission: Permissions.STORE_SPECIAL_FILE,
        },
      },
      {
        path: "/special-files/update/:id",
        name: "update-special-files",
        component: () => import("@/views/pages/special-files/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_SPECIAL_FILE,
        },
      },
      {
        path: "/special-files/trash",
        name: "trashed-special-files",
        component: () => import("@/views/pages/special-files/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_SPECIAL_FILE,
        },
      },
      {
        path: "/special-files/comments",
        name: "special-files-comments",
        component: () => import("@/views/pages/special-files/Comments.vue"),
        meta: {
          permission: Permissions.INDEX_COMMENT_SPECIAL_FILE,
        },
      },
      {
        path: "/special-files/comments/:id",
        name: "special-file-comments",
        component: () => import("@/views/pages/special-files/Comments.vue"),
        meta: {
          permission: Permissions.INDEX_COMMENT_SPECIAL_FILE,
        },
      },
      {
        path: "/special-files/comments/trash",
        name: "trashed-special-files-comments",
        component: () =>
          import("@/views/pages/special-files/CommentsTrash.vue"),
        meta: {
          permission: Permissions.TRASH_SPECIAL_FILE,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/special-coverings",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/special-coverings",
        name: "view-special-coverings",
        component: () => import("@/views/pages/special-coverings/Index.vue"),
        meta: {
          permission: Permissions.INDEX_SPECIAL_COVERINGS,
        },
      },
      {
        path: "/special-coverings/create",
        name: "create-special-coverings",
        component: () => import("@/views/pages/special-coverings/Create.vue"),
        meta: {
          permission: Permissions.STORE_SPECIAL_COVERING,
        },
      },
      {
        path: "/special-coverings/update/:id",
        name: "update-special-coverings",
        component: () => import("@/views/pages/special-coverings/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_SPECIAL_COVERING,
        },
      },
      {
        path: "/special-coverings/trash",
        name: "trashed-special-coverings",
        component: () => import("@/views/pages/special-coverings/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_SPECIAL_COVERING,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/guests",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/visitors",
        name: "view-guests",
        component: () => import("@/views/pages/guests/Index.vue"),
        meta: {
          permission: Permissions.INDEX_VISITOR,
        },
      },
      {
        path: "/visitors/create",
        name: "create-guests",
        component: () => import("@/views/pages/guests/Create.vue"),
        meta: {
          permission: Permissions.STORE_VISITOR,
        },
      },
      {
        path: "/visitors/update/:id",
        name: "edit-guests",
        component: () => import("@/views/pages/guests/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_VISITOR,
        },
      },
      {
        path: "/visitors/trash",
        name: "trashed-guests",
        component: () => import("@/views/pages/guests/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_VISITOR,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/authors",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/authors",
        name: "view-authors",
        component: () => import("@/views/pages/authors/Index.vue"),
        meta: {
          permission: Permissions.INDEX_WRITER,
        },
      },
      {
        path: "/authors/create",
        name: "create-authors",
        component: () => import("@/views/pages/authors/Form.vue"),
        meta: {
          permission: Permissions.STORE_WRITER,
        },
      },
      {
        path: "/authors/update/:id",
        name: "edit-authors",
        component: () => import("@/views/pages/authors/Form.vue"),
        meta: {
          permission: Permissions.UPDATE_WRITER,
        },
      },
      {
        path: "/authors/trash",
        name: "trashed-authors",
        component: () => import("@/views/pages/authors/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_WRITER,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/visual-reports",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/visual-reports",
        name: "view-visual-reports",
        component: () => import("@/views/pages/visual-reports/Index.vue"),
        meta: {
          permission: Permissions.INDEX_PHOTO_REPORT,
        },
      },
      {
        path: "/visual-reports/create",
        name: "create-visual-reports",
        component: () => import("@/views/pages/visual-reports/Create.vue"),
        meta: {
          permission: Permissions.STORE_PHOTO_REPORT,
        },
      },
      {
        path: "/visual-reports/update/:id",
        name: "edit-visual-reports",
        component: () => import("@/views/pages/visual-reports/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_PHOTO_REPORT,
        },
      },
      {
        path: "/visual-reports/trash",
        name: "trashed-visual-reports",
        component: () => import("@/views/pages/visual-reports/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_PHOTO_REPORT,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/ads",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/ads",
        name: "view-ad",
        component: () => import("@/views/pages/ads/show.vue"),
        meta: {
          permission: Permissions.INDEX_ADS,
        },
      },
      {
        path: "/ads/create",
        name: "create-ads",
        component: () => import("@/views/pages/ads/Create.vue"),
        meta: {
          permission: Permissions.STORE_ADS,
        },
      },
      {
        path: "/ads/update/:id",
        name: "edit-ads",
        component: () => import("@/views/pages/ads/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_ADS,
        },
      },
      {
        path: "/ads/trash",
        name: "trashed-ads",
        component: () => import("@/views/pages/ads/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_ADS,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/video-albums",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/video-albums",
        name: "view-video-albums",
        component: () => import("@/views/pages/video-albums/Index.vue"),
        meta: {
          permission: Permissions.INDEX_VIDEOS_ALBUM,
        },
      },
      {
        path: "/video-albums/create",
        name: "create-video-albums",
        component: () => import("@/views/pages/video-albums/Create.vue"),
        meta: {
          permission: Permissions.STORE_VIDEOS_ALBUM,
        },
      },
      {
        path: "/video-albums/update/:id",
        name: "edi-video-albums",
        component: () => import("@/views/pages/video-albums/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_VIDEOS_ALBUM,
        },
      },
      {
        path: "/video-albums/show/:id",
        name: "show-video-albums",
        component: () => import("@/views/pages/video-albums/VidoesManage.vue"),
        meta: {
          permission: Permissions.INDEX_VIDEO_ALBUM_MANAGEMENT,
        },
      },
      {
        path: "/video-albums/show",
        name: "display-video-albums",
        component: () => import("@/views/pages/video-albums/VidoesManage.vue"),
        meta: {
          permission: Permissions.INDEX_VIDEO_ALBUM_MANAGEMENT,
        },
      },
      {
        path: "/video-albums/trash",
        name: "trashed-video-albums",
        component: () => import("@/views/pages/video-albums/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_VIDEOS_ALBUM,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/image-albums",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/image-albums",
        name: "view-image-albums",
        component: () => import("@/views/pages/image-albums/Index.vue"),
        meta: {
          permission: Permissions.INDEX_IMAGES_ALBUM,
        },
      },
      {
        path: "/image-albums/create",
        name: "create-image-albums",
        component: () => import("@/views/pages/image-albums/Create.vue"),
        meta: {
          permission: Permissions.STORE_IMAGES_ALBUM,
        },
      },
      {
        path: "/image-albums/update/:id",
        name: "edi-image-albums",
        component: () => import("@/views/pages/image-albums/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_IMAGES_ALBUM,
        },
      },
      {
        path: "/image-albums/show/:id",
        name: "show-image-albums",
        component: () => import("@/views/pages/image-albums/ImagesManage.vue"),
        meta: {
          permission: Permissions.INDEX_IMAGES_ALBUM_MANAGEMENT,
        },
      },
      {
        path: "/image-albums/show",
        name: "display-image-albums",
        component: () => import("@/views/pages/image-albums/ImagesManage.vue"),
        meta: {
          permission: Permissions.INDEX_IMAGES_ALBUM_MANAGEMENT,
        },
      },
      {
        path: "/image-albums/trash",
        name: "trashed-image-albums",
        component: () => import("@/views/pages/image-albums/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_IMAGES_ALBUM,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/static-pages",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/static-pages",
        name: "view-static-pages",
        component: () => import("@/views/pages/static-pages/Index.vue"),
        meta: {
          permission: Permissions.INDEX_STATIC_CONTENT_PAGE,
        },
      },
      {
        path: "/static-pages/create",
        name: "create-static-pages",
        component: () => import("@/views/pages/static-pages/Create.vue"),
        meta: {
          permission: Permissions.STORE_STATIC_CONTENT_PAGE,
        },
      },
      {
        path: "/static-pages/update/:id",
        name: "edit-static-pages",
        component: () => import("@/views/pages/static-pages/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_STATIC_CONTENT_PAGE,
        },
      },
      {
        path: "/static-pages/trash",
        name: "trashed-static-pages",
        component: () => import("@/views/pages/static-pages/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_STATIC_CONTENT_PAGE,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/polls",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/polls",
        name: "view-polls",
        component: () => import("@/views/pages/polls/Index.vue"),
        meta: {
          permission: Permissions.INDEX_POLL,
        },
      },
      {
        path: "/polls/create",
        name: "create-polls",
        component: () => import("@/views/pages/polls/Create.vue"),
        meta: {
          permission: Permissions.STORE_POLL,
        },
      },
      {
        path: "/polls/edit/:id",
        name: "edit-polls",
        component: () => import("@/views/pages/polls/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_POLL,
        },
      },
      {
        path: "/polls/trash",
        name: "trashed-polls",
        component: () => import("@/views/pages/polls/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_POLL,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/reports/admin-activities",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/reports/admin-activities",
        name: "view-admin-activities-report",
        component: () => import("@/views/pages/reports/AdminActivities.vue"),
        meta: {
          permission: Permissions.GET_ADMINS_REPORT,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/reports/cartoonists",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/reports/cartoonists",
        name: "view-reports-cartoonists",
        component: () => import("@/views/pages/reports/Cartonnists.vue"),
        meta: {
          permission: Permissions.GET_CARTOONISTS_REPORT,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/reports/programs",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/reports/programs",
        name: "view-programs-report",
        component: () => import("@/views/pages/reports/Programs.vue"),
        meta: {
          permission: Permissions.GET_PREPARED_PROGRAM_REPORT,
        },
      },
    ],
  },

  {
    path: "/",
    redirect: "/reports/guests",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/reports/guests",
        name: "view-guests-report",
        component: () => import("@/views/pages/reports/Guests.vue"),
        meta: {
          permission: Permissions.GET_VISITORS_REPORT,
        },
      },
    ],
  },

  {
    path: "/",
    redirect: "/users",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/users",
        name: "view-users",
        component: () => import("@/views/pages/users/Index.vue"),
        meta: {
          permission: Permissions.INDEX_USER,
        },
      },
      {
        path: "/users/create",
        name: "create-users",
        component: () => import("@/views/pages/users/Form.vue"),
        meta: {
          permission: Permissions.STORE_USER,
        },
      },
      {
        path: "/users/update/:id",
        name: "update-users",
        component: () => import("@/views/pages/users/Form.vue"),
        meta: {
          permission: Permissions.UPDATE_USER,
        },
      },
      {
        path: "/users/trash",
        name: "trashed-users",
        component: () => import("@/views/pages/users/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_USER,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/programs-loop",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/programs-loop",
        name: "view-programs-loop",
        component: () => import("@/views/pages/programs-loop/Index.vue"),
        meta: {
          permission: Permissions.INDEX_PROGRAM_CYCLES,
        },
      },
      {
        path: "/programs-loop/create",
        name: "create-programs-loop",
        component: () => import("@/views/pages/programs-loop/Create.vue"),
        meta: {
          permission: Permissions.STORE_PROGRAM_CYCLES,
        },
      },
      {
        path: "/programs-loop/update/:id",
        name: "update-programs-loop",
        component: () => import("@/views/pages/programs-loop/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_PROGRAM_CYCLES,
        },
      },
      {
        path: "/programs-loop/trash",
        name: "trashed-programs-loop",
        component: () => import("@/views/pages/programs-loop/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_PROGRAM_CYCLES,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/episodes",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/episodes",
        name: "view-episodes",
        component: () => import("@/views/pages/episodes/Index.vue"),
        meta: {
          permission: Permissions.INDEX_EPISODE,
        },
      },
      {
        path: "/episodes/create",
        name: "create-episodes",
        component: () => import("@/views/pages/episodes/Create.vue"),
        meta: {
          permission: Permissions.STORE_EPISODE,
        },
      },
      {
        path: "/episodes/update/:id",
        name: "update-episodes",
        component: () => import("@/views/pages/episodes/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_EPISODE,
        },
      },
      {
        path: "/episodes/trash",
        name: "trashed-episodes",
        component: () => import("@/views/pages/episodes/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_EPISODE,
        },
      },
      {
        path: "/episodes/comments",
        name: "episodes-comments",
        component: () => import("@/views/pages/episodes/Comments.vue"),
        meta: {
          permission: Permissions.COMMENTS_MANAGEMENT_EPISODE,
        },
      },
      {
        path: "/episodes/comments/:id",
        name: "episode-comments",
        component: () => import("@/views/pages/episodes/Comments.vue"),
        meta: {
          permission: Permissions.COMMENTS_MANAGEMENT_EPISODE,
        },
      },
      {
        path: "/episodes/comments/trash",
        name: "trashed-episodes-comments",
        component: () => import("@/views/pages/episodes/CommentsTrash.vue"),
        meta: {
          permission: Permissions.GET_TRASH_EPISODE_COMMENT_EPISODE,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/broadcast",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/time-table",
        name: "time-table",
        component: () => import("@/views/pages/broadcasts/time-table.vue"),
        meta: {
          permission: Permissions.INDEX_BROADCAST_SCHEDULE,
        },
      },
      {
        path: "/broadcast",
        name: "view-broadcast",
        component: () => import("@/views/pages/broadcasts/Index.vue"),
        meta: {
          permission: Permissions.INDEX_BROADCAST_SCHEDULE,
        },
      },
      {
        path: "/coverage",
        name: "view-coverage",
        component: () => import("@/views/pages/broadcasts/Coverage.vue"),
        meta: {
          permission: Permissions.INDEX_LIVE_COVERAGE,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/roles",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/roles",
        name: "view-roles",
        component: () => import("@/views/pages/roles/Index.vue"),
        meta: {
          permission: Permissions.INDEX_ROLE,
        },
      },
      {
        path: "/roles/create",
        name: "create-roles",
        component: () => import("@/views/pages/roles/Create.vue"),
        meta: {
          permission: Permissions.STORE_ROLE,
        },
      },
      {
        path: "/roles/update/:id",
        name: "update-roles",
        component: () => import("@/views/pages/roles/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_ROLE,
        },
      },
      {
        path: "/roles/trash",
        name: "trashed-roles",
        component: () => import("@/views/pages/roles/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_ROLE,
        },
      },
    ],
  },

  // الثوابت
  {
    path: "/",
    redirect: "/countries",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/countries",
        name: "view-countries",
        component: () => import("@/views/pages/countries/Index.vue"),
        meta: {
          permission: Permissions.INDEX_COUNTRY,
        },
      },
      {
        path: "/countries/create",
        name: "create-countries",
        component: () => import("@/views/pages/countries/Create.vue"),
        meta: {
          permission: Permissions.STORE_COUNTRY,
        },
      },
      {
        path: "/countries/update/:id",
        name: "edit-countries",
        component: () => import("@/views/pages/countries/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_COUNTRY,
        },
      },
      {
        path: "/countries/trash",
        name: "trashed-countries",
        component: () => import("@/views/pages/countries/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_COUNTRY,
        },
      },
    ],
  },

  {
    path: "/",
    redirect: "/jobs",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/jobs",
        name: "view-jobs",
        component: () => import("@/views/pages/jobs/Index.vue"),
        meta: {
          permission: Permissions.INDEX_USER_JOB,
        },
      },
      {
        path: "/jobs/create",
        name: "create-jobs",
        component: () => import("@/views/pages/jobs/Create.vue"),
        meta: {
          permission: Permissions.STORE_USER_JOB,
        },
      },
      {
        path: "/jobs/update/:id",
        name: "update-jobs",
        component: () => import("@/views/pages/jobs/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_USER_JOB,
        },
      },
      {
        path: "/jobs/trash",
        name: "trashed-jobs",
        component: () => import("@/views/pages/jobs/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_USER_JOB,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/tags",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/tags",
        name: "view-tags",
        component: () => import("@/views/pages/tags/Index.vue"),
        meta: {
          permission: Permissions.INDEX_TAG,
        },
      },
      {
        path: "/tags/create",
        name: "create-tags",
        component: () => import("@/views/pages/tags/Create.vue"),
        meta: {
          permission: Permissions.STORE_TAG,
        },
      },
      {
        path: "/tags/update/:id",
        name: "update-tags",
        component: () => import("@/views/pages/tags/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_TAG,
        },
      },
      {
        path: "/tags/trash",
        name: "trashed-tags",
        component: () => import("@/views/pages/tags/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_TAG,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/news-types",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/news-types",
        name: "view-news-types",
        component: () => import("@/views/pages/news-types/Index.vue"),
        meta: {
          permission: Permissions.INDEX_NEWS_TYPE,
        },
      },
      {
        path: "/news-types/create",
        name: "create-news-types",
        component: () => import("@/views/pages/news-types/Create.vue"),
        meta: {
          permission: Permissions.STORE_NEWS_TYPE,
        },
      },
      {
        path: "/news-types/edit/:id",
        name: "edit-news-types",
        component: () => import("@/views/pages/news-types/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_NEWS_TYPE,
        },
      },
      {
        path: "/news-types/trash",
        name: "trashed-news-types",
        component: () => import("@/views/pages/news-types/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_NEWS_TYPE,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/news-categories",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/news-categories",
        name: "view-news-categories",
        component: () => import("@/views/pages/news-categories/Index.vue"),
        meta: {
          permission: Permissions.INDEX_NEWS_CATEGORIES,
        },
      },
      {
        path: "/news-categories/create",
        name: "create-news-categories",
        component: () => import("@/views/pages/news-categories/Create.vue"),
        meta: {
          permission: Permissions.STORE_NEWS_CATEGORIES,
        },
      },
      {
        path: "/news-categories/edit/:id",
        name: "edit-news-categories",
        component: () => import("@/views/pages/news-categories/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_NEWS_CATEGORIES,
        },
      },
      {
        path: "/news-categories/trash",
        name: "trashed-news-categories",
        component: () => import("@/views/pages/news-categories/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_NEWS_CATEGORIES,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/content-categories",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/content-categories",
        name: "view-content-categories",
        component: () => import("@/views/pages/content-categories/Index.vue"),
        meta: {
          permission: Permissions.INDEX_CONTENT_CATEGORIES,
        },
      },
      {
        path: "/content-categories/create",
        name: "create-content-categories",
        component: () => import("@/views/pages/content-categories/Create.vue"),
        meta: {
          permission: Permissions.STORE_CONTENT_CATEGORY,
        },
      },
      {
        path: "/content-categories/edit/:id",
        name: "edit-content-categories",
        component: () => import("@/views/pages/content-categories/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_CONTENT_CATEGORY,
        },
      },
      {
        path: "/content-categories/trash",
        name: "trashed-content-categories",
        component: () => import("@/views/pages/content-categories/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_CONTENT_CATEGORY,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/album-categories",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/album-categories",
        name: "view-images-videos-categories",
        component: () =>
          import("@/views/pages/images-videos-categories/Index.vue"),
        meta: {
          permission: Permissions.INDEX_IMAGES_VIDEOS_CATEGORIES,
        },
      },
      {
        path: "/album-categories/create",
        name: "create-images-videos-categories",
        component: () =>
          import("@/views/pages/images-videos-categories/Create.vue"),
        meta: {
          permission: Permissions.STORE_IMAGES_VIDEOS_CATEGORIES,
        },
      },
      {
        path: "/album-categories/update/:id",
        name: "edit-images-videos-categories",
        component: () =>
          import("@/views/pages/images-videos-categories/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_IMAGES_VIDEOS_CATEGORIES,
        },
      },
      {
        path: "/album-categories/trash",
        name: "trashed-images-videos-categories",
        component: () =>
          import("@/views/pages/images-videos-categories/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_IMAGES_VIDEOS_CATEGORIES,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/program-categories",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/program-categories",
        name: "view-program-categories",
        component: () => import("@/views/pages/program-categories/Index.vue"),
        meta: {
          permission: Permissions.INDEX_TV_PROGRAM_CATEGORIES,
        },
      },
      {
        path: "/program-categories/create",
        name: "create-program-categories",
        component: () => import("@/views/pages/program-categories/Create.vue"),
        meta: {
          permission: Permissions.STORE_TV_PROGRAM_CATEGORIES,
        },
      },
      {
        path: "/program-categories/update/:id",
        name: "edit-program-categories",
        component: () => import("@/views/pages/program-categories/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_TV_PROGRAM_CATEGORIES,
        },
      },
      {
        path: "/program-categories/trash",
        name: "trashed-program-categories",
        component: () => import("@/views/pages/program-categories/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_TV_PROGRAM_CATEGORIES,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/program-types",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/program-types",
        name: "view-program-types",
        component: () => import("@/views/pages/program-types/Index.vue"),
        meta: {
          permission: Permissions.INDEX_TV_PROGRAM_TYPE,
        },
      },
      {
        path: "/program-types/create",
        name: "create-program-types",
        component: () => import("@/views/pages/program-types/Create.vue"),
        meta: {
          permission: Permissions.STORE_TV_PROGRAM_TYPE,
        },
      },
      {
        path: "/program-types/update/:id",
        name: "edit-program-types",
        component: () => import("@/views/pages/program-types/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_TV_PROGRAM_TYPE,
        },
      },
      {
        path: "/program-types/trash",
        name: "trashed-program-types",
        component: () => import("@/views/pages/program-types/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_TV_PROGRAM_TYPE,
        },
      },
    ],
  },
  // --------------------
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/auth/oauth/authorize",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  //------------------------
  //settings
  {
    path: "/",
    redirect: "/settings",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "news/settings",
        name: "news-settings",
        component: () => import("@/views/pages/settings/NewsSettings.vue"),
        meta: {
          permission: Permissions.GENERAL_SETTINGS,
        },
      },
      {
        path: "program/settings",
        name: "program-settings",
        component: () => import("@/views/pages/settings/ProgramSettings.vue"),
        meta: {
          permission: Permissions.PROGRAM_PORTAL_SETTINGS,
        },
      },
      {
        path: "/broadcast/schedule/settings",
        name: "/broadcast-schedule-settings",
        component: () =>
          import("@/views/pages/settings/BroadCastScheduleSettings.vue"),
        meta: {
          permission: Permissions.BROADCAST_SCHEDULE_SETTINGS,
        },
      },
      {
        path: "/settings/watermark",
        name: "settings-watermark",
        component: () => import("@/views/pages/settings/Watermark.vue"),
        meta: {
          permission: Permissions.WATER_MARK_POSITION,
        },
      },
      {
        path: "/settings/news-layout",
        name: "settings-news-layout",
        component: () => import("@/views/pages/settings/NewsLayout.vue"),
        meta: {
          permission: Permissions.MAIN_NEWS_POSITION,
        },
      },
      {
        path: "/settings/menus",
        name: "settings-menus-manage",
        component: () => import("@/views/pages/settings/Menus.vue"),
        meta: {
          permission: Permissions.MENUS_MANAGE,
        },
      },
      {
        path: "/settings/menu-manage/:id",
        name: "settings-menu-manage-update",
        component: () => import("@/views/pages/settings/MenusManage.vue"),
        meta: {
          permission: Permissions.MENU_MANAGE,
        },
      },
      {
        path: "/settings/menu-manage",
        name: "settings-menu-manage",
        component: () => import("@/views/pages/settings/MenusManage.vue"),
        meta: {
          permission: Permissions.MENUS_ELEMENTS_MANAGE,
        },
      },
      {
        path: "/settings/main-page-sections",
        name: "settings-main-page-sections",
        component: () =>
          import("@/views/pages/settings/mainPageSections/Index.vue"),
        meta: {
          permission: Permissions.SHOW_SECTIONS,
        },
      },
      {
        path: "/settings/main-page-sections/trash",
        name: "settings-main-page-sections-trash",
        component: () =>
          import("@/views/pages/settings/mainPageSections/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_MAIN_PAGE_SECTIONS,
        },
      },
      {
        path: "/settings/main-page-sections/create",
        name: "create-main-page-section",
        component: () =>
          import("@/views/pages/settings/mainPageSections/Create.vue"),
        meta: {
          permission: Permissions.ADD_SECTION,
        },
      },
      {
        path: "/settings/main-page-sections/update/:id",
        name: "update-main-page-section",
        component: () =>
          import("@/views/pages/settings/mainPageSections/Create.vue"),
        meta: {
          permission: Permissions.EDIT_SECTION,
        },
      },
    ],
  },

  // بوابة اعداد البرامج
  {
    path: "/",
    redirect: "/hosting-mechanisms",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/hosting-mechanisms",
        name: "view-hosting-mechanisms",
        component: () => import("@/views/pages/hosting-mechanisms/Index.vue"),
        meta: {
          permission: Permissions.INDEX_MECHANISMS_GUESTS_PROGRAM,
        },
      },
      {
        path: "/hosting-mechanisms/create",
        name: "create-hosting-mechanisms",
        component: () => import("@/views/pages/hosting-mechanisms/Create.vue"),
        meta: {
          permission: Permissions.STORE_MECHANISMS_GUESTS_PROGRAM,
        },
      },
      {
        path: "/hosting-mechanisms/edit/:id",
        name: "edit-hosting-mechanisms",
        component: () => import("@/views/pages/hosting-mechanisms/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_MECHANISMS_GUESTS_PROGRAM,
        },
      },
      {
        path: "/hosting-mechanisms/trash",
        name: "trashed-hosting-mechanisms",
        component: () => import("@/views/pages/hosting-mechanisms/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_MECHANISMS_GUESTS_PROGRAM,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/programs",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/programs",
        name: "view-programs",
        component: () => import("@/views/pages/programs/Index.vue"),
        meta: {
          permission: Permissions.INDEX_PROGRAM,
        },
      },
      {
        path: "/programs/create",
        name: "create-programs",
        component: () => import("@/views/pages/programs/Create.vue"),
        meta: {
          permission: Permissions.STORE_PROGRAM,
        },
      },
      {
        path: "/programs/edit/:id",
        name: "edit-programs",
        component: () => import("@/views/pages/programs/Create.vue"),
        meta: {
          permission: Permissions.UPDATE_PROGRAM,
        },
      },
      {
        path: "/programs/trash",
        name: "trashed-programs",
        component: () => import("@/views/pages/programs/Trash.vue"),
        meta: {
          permission: Permissions.TRASH_PROGRAM,
        },
      },
    ],
  },

  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/401",
    name: "401",
    component: () => import("@/views/crafted/authentication/Error401.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  await store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

  ApiService.setHeader();

  const can = computed(() => store.getters.hasPermission);

  if (
    to.name !== "sign-in" &&
    !!JwtService.getToken() &&
    !store.getters.isUserAuthenticated
  ) {
    next();
  } else if (to.name !== "sign-in" && !store.getters.isUserAuthenticated) {
    next({ name: "sign-in" });
  } else if (
    to.name !== "401" &&
    to.meta.permission &&
    !can.value(to.meta.permission)
  ) {
    next({ name: "401" });
  } else if (to.name === "sign-in" && !!JwtService.getToken()) {
    next({ name: "dashboard" });
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.afterEach(() => {
  setTimeout(() => {
    store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
  }, 500);
});

export default router;
